body {
  background: #76b852;  /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #76b852, #134E5E);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #76b852, #134E5E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
font-family: sans-serif;
}
.emphasize-font {
  font-weight: bold;
}
.front-topic {
  background-color: rgba(255,255,255,0.2);
  margin-left: -20%;
  padding-left: 20%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.button-strap {
  border: none;
  background-color: #8ec44f;
  color: white;
  border: 1px solid #8ec44f;
  border-radius: 3px;
  padding: 7px 15px;
  font-size: 13px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.button-strap:hover {
  border: 1px solid white;
  background-color: white;
  color: #8ec44f;
  cursor:pointer;
	transition: all 0.4s;
}
.running {
  width: 60%;
  z-index: -20;
  position: absolute;
  margin-left: 30%;
  margin-top: -5%;
}
.navLink-override {
  color: white;
  background-color: transparent;
  border: none;
  font-size: 18px;
  margin-left: 10px;
}
.navLink-override:hover {
  color: black;
  cursor:pointer;
	transition: all 0.4s;
}
.navItemP {
  margin-left: 60%;
}
.layout {
  margin-left: 20px;
}

.first-content {
  margin-top: 10%;
  text-align: center;
  color: white;
}
.first-content p {
  margin-bottom: -5px;
}
.btn_more {
  margin-top: 18%;
}
.btn_down {
  margin-top: 15%;
}
#featurePage {
  text-align: center;
  margin-top: 18%;
  color: white;
  margin-bottom: 20%;
}

#featurePage img {
  width: 30%;
  margin-bottom: 5%;
}

#downloadPage {
  color: white;
}
.download {
  margin-bottom: 20%;

}
.android-style {
  margin-top: 42%;
}
.download-font {
  margin-top: -15%;
  font-size: 13px;
  margin-bottom: 12%;
  background-color: rgba(255,255,255,0.2);
  width: 210%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.footer {
  margin-top: 5%;
  background-color: black;
  margin-left: -40px;
  color: white;
  padding: 10px;
}
/* .img-animation img:hover {
  transform: scale(1.4);
  transition: all 0.6s;
}
.img-animation img {
  transition: all 0.6s;
} */
.img-animation img{
  padding-top: 10px;
}
.img-animation p{
  padding-bottom: 10px;
}
.img-animation {
  transition: all 0.6s;
}
.img-animation:hover {
  transform: scale(1.2);
  transition: all 0.6s;
  background-color: rgba(255,255,255,0.2);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
